import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Seo from "../components/seo"
import Img from "gatsby-image"

import Layout from "../components/layout"
// import Seo from "../components/seo"
import Wave from "../components/wave"


import * as style from "./home.module.css"
import PostsGridEachCateg from "../components/postsGridEachCateg"


const Home = () => {

  const data = useStaticQuery(graphql`
  fragment seoHomeData on WpPage {
    seo {
      title
      metaDesc
      opengraphImage {
        localFile {
          publicURL
        }
      }
    }
  }
  {
    homePage: wpPage(isFrontPage: {eq: true}) {
      ...seoHomeData
      title
      content
      featuredImage {
        node {
          localFile {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    secondImage: file(name: {eq: "decor-decoration-704147-20210405-square"}) {
      childImageSharp {
          fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
          }
      }
    }
#    brandImage: file(name: {eq: "ecofeminist-mama"}) {
#      childImageSharp {
#          fluid(maxWidth: 686, quality: 90) {
#          ...GatsbyImageSharpFluid
#          }
#      }
#  }
}
  `
  )


  const { homePage: page, secondImage } = data;

  // const ogImageUrl = homePage.gatsbyCustomFields.heroImage?.mediaItemUrl;

  return (
    <Layout>
      <Seo
        title={page.title}
        ogTitle={data.site.siteMetadata.title}
        seo={page.seo}
      />

      <div className='side-by-side-grid'>

        <div
          className={`${style.headerText} center-horiz-and-vertical center-text`}
          style={{ order: '2' }}
        >
          <h1>hello and welcome&nbsp;to</h1>

          <img
            className={style.logo}
            src={`/image/ecofeminist-mama.svg`}
            alt='logo'
            width='100%'
          />

          {/* <Img
            fluid={brandImage.childImageSharp.fluid}
            alt='ecofeminist mama'
            style={{ width: "96%", margin: "10px" }}
          /> */}

          <h3>
            Your guide to ethical pregnancy, birth & baby-raising.
          </h3>

          <p>
            My name is Gudrun Getz and I’m a full-time mother, writer and postgraduate researcher. I set up this site to help you navigate all things ethical and natural pregnancy, birth and mothering.
          </p>
          <p>
            If you’d like to get to know me better, <Link to='/about-gudrun/'>click here</Link>. I&nbsp;hope you enjoy your visit!
          </p>
        </div>

        <div
          style={{ order: '1' }}   // ??? use media query to put this first *only on narrow screen??*
        // or do we actually carE? just always have it first (and reverse order below.. - oh wait, no cos then it'll be weird on a phone)
        >
          <Img
            fluid={page.featuredImage.node.localFile.childImageSharp.fluid}
            alt={page.featuredImage.node.altText}
            imgStyle={{ clipPath: 'circle(50%)' }}  // clip to avoid weird boxy effect when blurred 'loading' version is showing
          />
        </div>

      </div>

      <Wave variation={5} />
      <section className={style.carouselSection}>
        <div className='side-by-side-grid'>
          <div className='center-horiz-and-vertical'>
            <h2>
              I believe that, as mothers of the next generation, we have the power to change the world!
            </h2>
            <p>
              How we raise our children reflects the kind of world we want them to grow up in. Join me in starting and sharing the powerful Ecofeminist Mama revolution and give birth both to an extraordinary generation and a sustainable planet.
            </p>
            <div className='center-horiz'>
              <Link
                to='/what-is-ecofeminism/'
                role='button'
                className='link-button'
              >
                Find Out More
              </Link>
            </div>
          </div>
          <div>
            <Img
              fluid={secondImage.childImageSharp.fluid}
              alt='Mother holding child and touching heads together'
              imgStyle={{ clipPath: 'circle(50%)' }}  // clip to avoid weird boxy effect when blurred 'loading' version is showing
            />
          </div>
        </div>
      </section>
      <Wave invert variation={7} />

      <PostsGridEachCateg />

    </Layout>
  )
}



export default Home

